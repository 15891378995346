import { createRouter,createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Frame',
    component: () => import('@/components/LayoutBox.vue'),
    redirect: 'home',
    children: [
      {
        path: '/:pathMatch(.*)*',
        name: 'notFound',
        component: () => import('@/views/notFound.vue'),
        meta: {
          title: "404",
        },
      },
      {
        path: '',
        name: 'home',
        component: () => import('@/views/pages/Home/contPage.vue'),
        meta: {
          title: "首页",
        },
      },
      {
        path: '/xmtjz',
        name: 'xmtjz',
        component: () => import('@/views/pages/Mtjz/contPage.vue'),
        meta: {
          title: "新媒体矩阵",
        },
      }, {
        path: '/lxwm',
        name: 'lxwm',
        component: () => import('@/views/pages/Lxwm/contPage.vue'),
        meta: {
          title: "联系我们",
        },
      },
      {
        path: '/ssjg',
        name: 'ssjg',
        component: () => import('@/views/pages/search/contPage.vue'),
        meta: {
          title: "搜索结果",
        },
      },
      // 医院概况
      {
        path: '/yyjj',
        name: 'yyjj',
        component: () => import('@/views/pages/yyjj/contPage.vue'),
        meta: {
          title: "医院简介",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/ldtd',
        name: 'ldtd',
        component: () => import('@/views/pages/ldtd/contPage.vue'),
        meta: {
          title: "领导团队",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/zzjg',
        name: 'zzjg',
        component: () => import('@/views/pages/zzjg/contPage.vue'),
        meta: {
          title: "组织架构",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/yywh',
        name: 'yywh',
        component: () => import('@/views/pages/yywh/contPage.vue'),
        meta: {
          title: "医院文化",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/lsyg',
        name: 'lsyg',
        component: () => import('@/views/pages/lsyg/contPage.vue'),
        meta: {
          title: "历史沿革",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/yydsj',
        name: 'yydsj',
        component: () => import('@/views/pages/yydsj/contPage.vue'),
        meta: {
          title: "医院大事记",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      }, {
        path: '/jctj',
        name: 'jctj',
        component: () => import('@/views/pages/jctj/contPage.vue'),
        meta: {
          title: "精彩图集",
          parentLevel: '医院概况',
          parentName: 'yyjj',
        },
      },
      //患者服务
      {
        path: '/jzfw',
        name: 'jzfw',
        component: () => import('@/views/pages/jzfw/contPage.vue'),
        meta: {
          title: "就诊服务",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/ybzl',
        name: 'ybzl',
        component: () => import('@/views/pages/ybzl/contPage.vue'),
        meta: {
          title: "医保指南",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/tszl',
        name: 'tszl',
        component: () => import('@/views/pages/tszl/contPage.vue'),
        meta: {
          title: "特色诊疗",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/mzsj',
        name: 'mzsj',
        component: () => import('@/views/pages/mzsj/contPage.vue'),
        meta: {
          title: "门诊时间",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/yygh',
        name: 'yygh',
        component: () => import('@/views/pages/yygh/contPage.vue'),
        meta: {
          title: "预约挂号",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/lyjt',
        name: 'lyjt',
        component: () => import('@/views/pages/lyjt/contPage.vue'),
        meta: {
          title: "来院交通",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/jktj',
        name: 'jktj',
        component: () => import('@/views/pages/jktj/contPage.vue'),
        meta: {
          title: "健康体检",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      }, {
        path: '/yjjy',
        name: 'yjjy',
        component: () => import('@/views/pages/yjjy/contPage.vue'),
        meta: {
          title: "意见建议",
          parentLevel: '患者服务',
          parentName: 'mzsj',
        },
      },
      //科室设置
      {
        path: '/kslb',
        name: 'kslb',
        component: () => import('@/views/pages/kslb/contPage.vue'),
        meta: {
          title: "科室列表",
          parentLevel: '科室设置',
          parentName: 'kslb',
        },
      }, {
        path: '/ksdt',
        name: 'ksdt',
        component: () => import('@/views/pages/ksdt/contPage.vue'),
        meta: {
          title: "科室动态",
          parentLevel: '科室设置',
          parentName: 'kslb',
        },
      },
      //专家医生
      {
        path: '/yslb',
        name: 'yslb',
        component: () => import('@/views/pages/yslb/contPage.vue'),
        meta: {
          title: "医生列表",
          parentLevel: '专家医生',
          parentName: 'yslb',
        },
      },
      //新闻中心
      {
        path: '/xwzx',
        name: 'xwzx',
        component: () => import('@/views/pages/xwzx/contPage.vue'),
        meta: {
          title: "新闻资讯",
          parentLevel: '新闻中心',
          parentName: 'xwzx',
        },
      }, {
        path: '/yygg',
        name: 'yygg',
        component: () => import('@/views/pages/yygg/contPage.vue'),
        meta: {
          title: "医院公告",
          parentLevel: '新闻中心',
          parentName: 'xwzx',
        },
      },
      //健康科普   
      {
        path: '/jkkp',
        name: 'jkkp',
        component: () => import('@/views/pages/jkkp/contPage.vue'),
        meta: {
          title: "健康科普",
          parentLevel: '健康科普',
          parentName: 'jkkp',
        },
      },
      //党建文化

      {
        path: '/djxw',
        name: 'djxw',
        component: () => import('@/views/pages/djxw/contPage.vue'),
        meta: {
          title: "党建新闻",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      },
      {
        path: '/ddjs',
        name: 'ddjs',
        component: () => import('@/views/pages/ddjs/contPage.vue'),
        meta: {
          title: "党的建设",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      }, {
        path: '/dflz',
        name: 'dflz',
        component: () => import('@/views/pages/dflz/contPage.vue'),
        meta: {
          title: "党风廉政",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      }, {
        path: '/zgzj',
        name: 'zgzj',
        component: () => import('@/views/pages/zgzj/contPage.vue'),
        meta: {
          title: "职工之家",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      }, {
        path: '/qngz',
        name: 'qngz',
        component: () => import('@/views/pages/qngz/contPage.vue'),
        meta: {
          title: "青年工作",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      },
      {
        path: '/byll',
        name: 'byll',
        component: () => import('@/views/pages/byll/contPage.vue'),
        meta: {
          title: "榜样力量",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      },
      {
        path: '/rwyy',
        name: 'rwyy',
        component: () => import('@/views/pages/rwyy/contPage.vue'),
        meta: {
          title: "人文医院",
          parentLevel: '党建文化',
          parentName: 'djxw',
        },
      },

      //科学研究
      {
        path: '/kjdt',
        name: 'kjdt',
        component: () => import('@/views/pages/kjdt/contPage.vue'),
        meta: {
          title: "科技动态",
          parentLevel: '科学研究',
          parentName: 'kjdt',
        },
      },
      {
        path: '/tzgg',
        name: 'tzgg',
        component: () => import('@/views/pages/tzgg/contPage.vue'),
        meta: {
          title: "通知公告",
          parentLevel: '科学研究',
          parentName: 'kjdt',
        },
      }, {
        path: '/kyxm',
        name: 'kyxm',
        component: () => import('@/views/pages/kyxm/contPage.vue'),
        meta: {
          title: "科研项目",
          parentLevel: '科学研究',
          parentName: 'kjdt',
        },
      }, {
        path: '/kycg',
        name: 'kycg',
        component: () => import('@/views/pages/kycg/contPage.vue'),
        meta: {
          title: "科研成果",
          parentLevel: '科学研究',
          parentName: 'kjdt',
        },
      },
      //信息公开
      {
        path: '/gzzd',
        name: 'gzzd',
        component: () => import('@/views/pages/gzzd/contPage.vue'),
        meta: {
          title: "规章制度",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      }, {
        path: '/zpgg',
        name: 'zpgg',
        component: () => import('@/views/pages/zpgg/contPage.vue'),
        meta: {
          title: "招聘公告",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      }, {
        path: '/ywdt',
        name: 'ywdt',
        component: () => import('@/views/pages/ywdt/contPage.vue'),
        meta: {
          title: "医务动态",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      }, {
        path: '/hlfc',
        name: 'hlfc',
        component: () => import('@/views/pages/hlfc/contPage.vue'),
        meta: {
          title: "护理风采",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      }, {
        path: '/pxdt',
        name: 'pxdt',
        component: () => import('@/views/pages/pxdt/contPage.vue'),
        meta: {
          title: "培训动态",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      }, {
        path: '/zcgg',
        name: 'zcgg',
        component: () => import('@/views/pages/zcgg/contPage.vue'),
        meta: {
          title: "招采公告",
          parentLevel: '信息公开',
          parentName: 'gzzd',
        },
      },

    ]
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router

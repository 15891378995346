import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "./router/routerHook.ts"

import store from './store'

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import axios from './http_api/axios'

// 生产环境清除所有console.log
if (process.env.NODE_ENV == 'production') {
    console.log = function (e) {
        // console.log('e')
    };
}



const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.use(store).use(router).use(ElementPlus).mount('#app')
app.config.globalProperties.$http = axios;  //配置axios的全局引用


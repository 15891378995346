import Axios from 'axios';
import { ElNotification } from 'element-plus';
import $store from '@/store/index'

const SM4 = require('gm-crypt').sm4

const baseURL = 'https://sscms.rtyljk.cn'
// const baseURL = 'http://sscms.cncsedu.com.cn'
let siteDir =''
const APIKEY='c09286d1-e463-46fb-81c5-a544f8d3adba'
const axios = Axios.create({
  baseURL:baseURL,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    'X-SS-API-KEY': APIKEY
  }
});
const sm4Config = {
  key: APIKEY.substr(7,16),
  mode: "ecb", // 加密的方式有两种，ecb和cbc两种，也是看后端如何定义的，不过要是cbc的话下面还要加一个iv的参数，ecb不用
  cipherType: "base64" // default is base64
};
const sm4 = new SM4(sm4Config);

function getWebsiteId() {
  // axios.get(`/api/ping?domain=xinyang154.rtyljk.cn`)
  // axios.get(`/api/ping?domain=shanghai411.rtyljk.cn`)
  // axios.get(`/api/ping?domain=www.121-hospital.com`)
  axios.get(`/api/ping?domain=${document.domain}`)
  .then(async (res: any) => {
    console.log("ping-res:", res);

    let { data } = res;
    if (data) {
      data = sm4.decrypt(data);
      res.data = data;
    }

    localStorage.setItem('websiteId', res.data.split(',')[0])
    $store.commit("setWebsiteId", res.data.split(',')[0]);

     siteDir = res.data.split(',')[1]
  })
  .catch((err: any) => {
    // console.log("ping-err:", err);
  });
}
getWebsiteId()

// 添加请求拦截器
axios.interceptors.request.use(function (config: any) {
  // console.log('config:',config)
  // if(config.url=='/api/v1/channels/null'){
  //   location.reload()
  // }
  // console.log('config.url:',config.url)
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  // console.log('请求错误=error=', error)
  return Promise.reject(error)
});

// 添加响应拦截器
axios.interceptors.response.use((response: any) => {
  if (response.status === 200) {
    let { data } = response.data;
    if (data) {
      data = sm4.decrypt(data);
      response.data.data = JSON.parse(data);
    }
    let replaceData=JSON.stringify(response.data)
    replaceData=JSON.stringify(response.data).replace(/"\/shyyyy\/upload/g, '"'+baseURL+'/'+'shyyyy'+'/upload')
    replaceData=JSON.stringify(JSON.parse(replaceData)).replace(/@\/upload/g, baseURL+'/'+siteDir+'/upload')

    // if(replaceData.includes('"/shyyyy/upload')){
    //   console.log('replaceData:',replaceData)
    // }else{
    // }

    response.data=JSON.parse(replaceData)
    return response.data.data;
  }
}, (error) => {
  // 对响应错误做点什么
  const { data } = error.response
  console.log('error出错了=error=', data)
  ElNotification({
    title: '错误',
    message: data.message || data,
    type: 'error',
  })
  return Promise.reject(data)
});

export default axios
import { createStore } from 'vuex'

export default createStore({
  state: {
    topTableTabs: JSON.parse(localStorage.getItem("topTableTabs") || `[]`),
    userName: localStorage.getItem("userName") || '用户名称',
    topTableTabsVal: localStorage.getItem("topTableTabsVal") || '',
    indexTree: [],
    indexArr: [],
    indexArr1: [],//单独的模块
    titleArr: [],
    bannerArr: [],
    websiteId:localStorage.getItem("websiteId") ||'',
  },
  getters: {
  },
  mutations: {
    setTopTableTabs(state, objData) {
      if (JSON.stringify(objData) == '[]') {
        state.topTableTabsVal = ''
        state.topTableTabs = []
      } else {
        const arr = state.topTableTabs
        arr.push({
          title: objData.title,
          name: objData.name,
          url: objData.url,
        });
        const result = [];
        const obj: any = {};
        for (let i = 0; i < arr.length; i++) {
          if (!obj[arr[i].name]) {
            result.push(arr[i]);
            obj[arr[i].name] = true;
          }
        }
        state.topTableTabsVal = objData.name
        state.topTableTabs = result
        localStorage.setItem("topTableTabs", JSON.stringify(state.topTableTabs))
        localStorage.setItem("topTableTabsVal", state.topTableTabsVal)
      }

    },
    setIndexTree(state, arr) {
      state.indexTree = arr
    },
    setIndexArr(state, arr) {
      state.indexArr = arr
    },
    setIndexArr1(state, arr) {
      state.indexArr1 = arr
    },
    setTitleArr(state, arr) {
      state.titleArr = arr
    },
    setBannerArr(state, arr) {
      state.bannerArr = arr
    },
    setWebsiteId(state, id) {
      state.websiteId = id
    },
  },
  actions: {
  },
  modules: {
  }
})
